<template>
  <div style="min-height: 80vh;background-color: #eaedf1;">
    <div class="main">
      <div class="top">
        <a-row :gutter="[24,24]" type="flex">
          <a-col :span="12" :xs="24" :md="12">
            <!-- 骨架屏 -->
            <el-skeleton :loading="topCollectLoading" animated>
              <template slot="template">
                <div>
                  <div class="top-card">
                    <div style="font-size: 24px;color: coral;">
                      <i class="el-icon-star-on" />
                    </div>
                    <div class="top-text">
                      <el-skeleton-item variant="text" style="text-align: left; margin: 33px 28px 26px 8px;width: 200px;" />
                    </div>
                    <i class="el-icon-arrow-right" />
                  </div>
                </div>
              </template>
              <template>
                <div>
                  <div class="top-card" @click="toshouc()">
                    <div style="font-size: 24px;color: coral;">
                      <i class="el-icon-star-on" />
                    </div>
                    <div class="top-text">
                      <span class="top-num">{{ collectTo }}</span> 已收藏
                    </div>
                    <i class="el-icon-arrow-right" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </a-col>

          <a-col :span="12" :xs="24" :md="12">
            <el-skeleton :loading="topOrderLoading" animated>
              <template slot="template">
                <div>
                  <div class="top-card">
                    <div style="font-size: 24px;color:mediumseagreen">
                      <i class="el-icon-s-order" />
                    </div>
                    <div class="top-text">
                      <el-skeleton-item variant="text" style="text-align: left; margin: 33px 28px 26px 8px;width: 200px;" />
                    </div>
                    <i class="el-icon-arrow-right" />
                  </div>
                </div>
              </template>
              <template>
                <div>
                  <div class="top-card" @click="toxiad()">
                    <div style="font-size: 24px;color:mediumseagreen">
                      <i class="el-icon-s-order" />
                    </div>
                    <div class="top-text">
                      <span class="top-num"> {{ orderTo }}</span> 已下单
                    </div>
                    <i class="el-icon-arrow-right" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </a-col>
        </a-row>
      </div>

      <!-- 骨架屏 -->
      <el-skeleton :loading="loading" animated>
        <template slot="template">
          <div class="content" style="max-width: 1000px;">
            <div class="content-font">
              <el-skeleton-item variant="text" style="margin-right: 16px;" />
              <el-skeleton-item variant="text" style="width: 30%;" />
            </div>
            <div class="content-spu">
              <el-skeleton-item variant="text" style="text-align: left;margin-bottom: 26px;">
                从V5推荐的热销商品中，选择商品采集到你的SHOPLINE店铺，0成本也能玩转独立站。
              </el-skeleton-item>
              <div class="card-layout">
                <a-row :gutter="[24,24]" type="flex">
                  <a-col v-for="(item,index) in spuList" :key="index" :span="6">
                    <el-card shadow="hover" class="content-spu-card">
                      <div class="image-s">
                        <el-skeleton-item
                          variant="image"
                          class="image-i"
                          style="min-height: 200px;"
                        />
                      </div>
                      <div class="info">
                        <el-skeleton-item variant="text" class="title">
                          名称
                        </el-skeleton-item>
                        <el-skeleton-item variant="text" class="title" style="margin-top:8px">
                          名称
                        </el-skeleton-item>
                      </div>
                      <div class="sub-price">
                        <el-skeleton-item variant="text" class="title" style="margin-top:8px">
                          价格
                        </el-skeleton-item>
                      </div>
                    </el-card>
                  </a-col>
                </a-row>
              </div>

              <!-- <div class="card-layout">
                <div v-for="(a , index) in spuList" :key="index" class="box">
                  <el-card shadow="hover" class="content-spu-card">
                    <div class="image-s">
                      <el-skeleton-item
                        variant="image"
                        class="image-i"
                      />
                    </div>
                  </el-card>
                </div>
              </div> -->

              <el-skeleton-item variant="text" type="primary" class="find-btn">
                查找更多商品
              </el-skeleton-item>
            </div>
          </div>
        </template>

        <template>
          <div class="content" style="max-width: 1000px;">
            <div class="content-font">
              <div>使用V5来采集你的商品</div>
              <el-select v-model="selectType" size="small" placeholder="请选择">
                <el-option
                  v-for="item in selectTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="content-spu">
              <div style="text-align: left;margin-bottom: 26px;">
                从V5推荐的热销商品中，选择商品采集到你的SHOPLINE店铺，0成本也能玩转独立站。
              </div>
              <div class="card-layout">
                <a-row :gutter="[24,24]" type="flex">
                  <a-col v-for="(item,index) in spuList" :key="index" :span="6" @click="getProduct(item)">
                    <el-card shadow="hover" class="content-spu-card">
                      <!-- 图片 -->
                      <div class="image-s">
                        <img class="image-i" :src="item.image" alt="" srcset="">
                      </div>
                      <div class="info">
                        <div class="title">
                          {{ item.subject }}
                        </div>
                      </div>
                      <div class="sub-price">
                        ¥ {{ item.wholesalePrice }}
                      </div>
                    </el-card>
                  </a-col>
                </a-row>
              </div>

              <a-button type="primary" class="find-btn" @click="searchProduct()">
                查找更多商品
              </a-button>
            </div>
          </div>
        </template>
      </el-skeleton>
      <div v-if="false" class="content" style="max-width: 1000px;margin-top: 30px;">
        <div class="content-font">
          <div>关注站点</div>
          <div />
        </div>
        <div class="content-spu">
          暂未关注站点
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUsercollect } from '@/api/register'
import { getOrderList } from '@/api/register'
import { getAlibabaProductList, getBrandProductList } from '@/api/register'
export default {
  data() {
    return {
      loading: true,
      topCollectLoading: true,
      topOrderLoading: true,
      spuList: [
        {
          label: '',
          image: ''
        },
        {
          label: '',
          image: ''
        },
        {
          label: '',
          image: ''
        },
        {
          label: '',
          image: ''
        },
        {
          label: '',
          image: ''
        },
        {
          label: '',
          image: ''
        },
        {
          label: '',
          image: ''
        },
        {
          label: '',
          image: ''
        }

      ],
      selectType: '1',
      selectTypeList: [
        { label: '阿里巴巴', value: '1' },
        { label: '品牌', value: '2' }
      ],
      collectTo: 0,
      orderTo: 0
    }
  },
  watch: {
    selectType(newValue) {
      console.log('被监听')
      if (newValue === '1') {
        this.getAlibabaProductList()
      } else {
        this.getBrandProductList()
      }
    }
  },
  mounted() {
    this.getAlibabaProductList()
    getUsercollect().then(res => {
      this.collectTo = res.data.total
      this.topCollectLoading = false
    })
    getOrderList().then(res => {
      this.orderTo = res.data.total
      this.topOrderLoading = false
    })
  },
  destroyed() {},
  methods: {
    getAlibabaProductList() {
      this.loading = true
      getAlibabaProductList({ size: 8 }).then(res => {
        this.spuList = res.data.records.slice(0, 8)
        this.loading = false
      })
    },
    getBrandProductList() {
      this.loading = true
      getBrandProductList({ size: 8 }).then(res => {
        this.spuList = res.data.records.slice(0, 8)
        this.loading = false
      })
    },
    searchProduct() {
      this.$router.push({ path: '/findproduct/index' })
    },
    getProduct(item) {
      console.log(item, '商品')
      if (!item.productId) {
        return
      }
      this.$router.push({ path: '/detailproduct/index', query: { productId: item.productId, selectType: this.selectType, product: item.wholesalePrice }})
    },

    toshouc() {
      this.$router.push({ path: '/favorite/index' })
    },

    toxiad() {
      this.$router.push({ path: '/purchased/index' })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  max-width: 1920px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}
.top {
  max-width: 1000px;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
}
.top-card {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 26px;
  height: 64px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  // margin: 0 10px;
}
.top-text{
  flex: 1;
  text-align: left;
}
.top-num{
  color: #356dff;
  margin: 0 0 0 6px;
  line-height: 64px;
}
// 卡片布局
.card-layout{
  display: flex;
  flex-flow: wrap;
}
.content{
  border-radius: 6px;
  width: 1000px;
  margin-bottom: 16px;
  background-color: #fff;
  &-font{
    text-align: left;
    border-radius: 6px 6px  0 0 ;
    background-color: #fff;
    font-size: 20px;
    height: 64px;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &-spu{
    z-index: 111;
    display: flex;
    flex-direction: column;
    padding: 2.8% 3.2%;;
    // min-height: 210px;
    background-color: rgb(247, 248, 251);
    &-card{
      cursor: pointer;
      width:100%;
      display: flex;
      align-items: center;
    }
  }
}
.box{
  // width: 25%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-s{
  background-color: #fff;
  width:100%;
  height:100%;
  position:relative;
}
.image-i{
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.info{
  margin: 10px 12px 10px;
  // width: 176px;
  height: 60px;
  border-bottom: 1px solid #e8e8e8;
}
.title{
    text-align: left;
    max-height: 48px;
    font-size: 14px;
    color: #242833;
    font-weight: 500;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    word-wrap:break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.sub-price{
  margin-top: 8px;
  color: #474f5e;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  margin: 8px 12px;
}
.find-btn{
  height: 36px;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 500;
  margin: 18px 0 0 0;
}
.find-btn:hover{
  color: #40a9ff;
  background: #fff;
}

::v-deep .el-card__body{
  padding: 0px !important;
}
</style>
